import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Head = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <Helmet
      title={`${title} | ${data.site.siteMetadata.title}`}
      meta={[
        { name: "description", content: "Portfolio" },
        { name: "theme-color", content: "#808FA7" },
      ]}
    >
      <html lang="en" />
      <link
        href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  )
}

export default Head
