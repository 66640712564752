import React from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import menu from "../images/menu.svg"
import SocialList from "./socialList"
import headerStyles from "./header.module.scss"
import { ThemeContext } from "../context/theme"

class Header extends React.Component {
  interval = null;
  state = {
    menu: false,
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      try {
        this.setState({
          background:
            document.getElementById("scroll").getBoundingClientRect().top < 0,
        })
      } catch (e) {
        clearInterval(this.interval)
      }
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  operateMenu = () => {
    this.setState({ menu: !this.state.menu })
  }

  render() {
    return (
      <header
        className={headerStyles.header}
        style={this.props.topSection ? { background: "#333" } : null}
      >
        <nav>
          <ul
            className={`${
              this.state.menu || this.state.background
                ? headerStyles.navListMenu
                : ""
            } ${headerStyles.navList}`}
          >
            <AniLink
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/"
              fade
            >
              <li>Home</li>
            </AniLink>
            <AniLink
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/blog"
              fade
            >
              <li>Blog</li>
            </AniLink>
            <AniLink
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/contact"
              fade
            >
              <li>Contact</li>
            </AniLink>
            <li className={headerStyles.navSubList} onClick={this.operateMenu}>
              <img
                src={menu}
                className={`${this.state.menu ? headerStyles.iconSpin : ""} ${
                  headerStyles.navSubList
                }`}
                alt="Menu"
              />
            </li>
          </ul>
          <SocialList />
        </nav>
        <div
          className={`${
            this.state.menu
              ? headerStyles.sideMenuVisible
              : headerStyles.sideMenuHidden
          } ${headerStyles.sideMenu}`}
          onClick={this.operateMenu}
        >
          <ul>
            <AniLink
              className={headerStyles.sideItem}
              activeClassName={headerStyles.activeSideItem}
              to="/"
              bg="#333"
              fade
            >
              <li>Home</li>
            </AniLink>
            <AniLink
              className={headerStyles.sideItem}
              activeClassName={headerStyles.activeSideItem}
              to="/contact"
              bg="#333"
              fade
            >
              <li>Contact</li>
            </AniLink>
            <AniLink
              className={headerStyles.sideItem}
              activeClassName={headerStyles.activeSideItem}
              to="/blog"
              bg="#333"
              fade
            >
              <li>Blog</li>
            </AniLink>
            <a
              className={headerStyles.sideItem}
              href="https://github.com/BillGeoghegan"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>GitHub</li>
            </a>
            <a
              className={headerStyles.sideItem}
              href="https://www.linkedin.com/in/billgeoghegan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>LinkedIn</li>
            </a>
            <a
              className={headerStyles.sideItem}
              href="https://twitter.com/billy_geoghegan"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>Twitter</li>
            </a>
            <a
              className={headerStyles.sideItem}
              href="https://codepen.io/billgeoghegan"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>CodePen</li>
            </a>
          </ul>
        </div>
      </header>
    )
  }
}

Header.contextType = ThemeContext
export default Header
