import React, { useContext } from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import linkedIn from "../images/linkedInLogo.svg"
import nightToggle from "../images/nightLogo.svg"
import dayToggle from "../images/dayLogo.svg"
import twitter from "../images/twitterLogo.svg"
import github from "../images/githubLogo.svg"
import codepen from "../images/codepenLogo.svg"
import email from "../images/emailLogo.svg"
import { ThemeContext } from "../context/theme"

import socialListStyles from "./socialList.module.scss"

const SocialList = () => {
  const {
    setTheme = {},
    state: { darkTheme = false } = {}, 
  } = useContext(ThemeContext) || {}
  return (
    <ul className={socialListStyles.socialList}>
      <li>
        <a
          href="https://www.linkedin.com/in/billgeoghegan/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={linkedIn}
            className={socialListStyles.icon}
            alt="LinkedIn"
          />
        </a>
      </li>
      <li>
        <AniLink
          to="/contact"
          bg="linear-gradient(#707daf, #4c5473)"
          direction="up"
          color={"white"}
          paintDrip
        >
          <img src={email} className={socialListStyles.icon} alt="Email" />
        </AniLink>
      </li>
      <li>
        <a
          href="https://twitter.com/billy_geoghegan"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitter} className={socialListStyles.icon} alt="Twitter" />
        </a>
      </li>
      <li>
        <a
          href="https://github.com/BillGeoghegan"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={github} className={socialListStyles.icon} alt="GitHub" />
        </a>
      </li>
      <li>
        <a
          href="https://codepen.io/BillGeoghegan"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={codepen} className={socialListStyles.icon} alt="CodePen" />
        </a>
      </li>
      <li>
        <div
          className={`
            ${socialListStyles.toggle}
            ${darkTheme && socialListStyles.toggleOff}
            `}
        >
          <img
            src={nightToggle}
            className={socialListStyles.icon}
            alt="Night mode toggle"
            onClick={() => setTheme(true)}
          />
          <img
            src={dayToggle}
            className={socialListStyles.icon}
            alt="Night mode toggle"
            onClick={() => setTheme(false)}
          />
        </div>
      </li>
    </ul>
  )
}

export default SocialList
