import React from "react"
import { styles, darkStyles } from "./themeStyles.json"

import "../styles/index.scss"
import Header from "../components/header"
import layoutStyles from "./layout.module.scss"
import bill from "../images/bill_small_shadow.png"
import { WorkplaceProvider } from "../context/workplace"
import { ThemeContext } from "../context/theme"

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.children = props.children;
    this.state = {
      count: 1
    }
  }

  incrementClick = () => {
    this.setState({
      count: this.state.count + 1
    })
    console.log(this.state.count)
  }

  resetClick = () => {
    this.setState({
      count: 1
    })
    console.log('reset')
  }

  render() {
    return (
      <WorkplaceProvider>
        <ThemeContext.Consumer>
          {({ state: { darkTheme = false } = {} } = {}) => (
            <div
              className={layoutStyles.layout}
              style={darkTheme ? darkStyles : styles}
            >
              <Header />
              <div className={layoutStyles.content}>{this.props.children}</div>
              <div className={layoutStyles.messageContainer}>
                <img
                  src={bill}
                  alt="A cartoon of Bill"
                  className={layoutStyles.bill}
                />
                <div className={layoutStyles.message}
                  id={layoutStyles[`message_${this.state.count}`]}
                  onClick={() => this.incrementClick()}
                  onBlur={() => this.resetClick()}
                  onMouseLeave={() => this.resetClick()}
                />
              </div>
            </div>
          )}
        </ThemeContext.Consumer>
      </WorkplaceProvider>
    )
  }
}

Layout.contextType = ThemeContext
export default Layout
